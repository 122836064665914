import styled from 'styled-components'
import { WHITE, SPUN_PEARL_GREY, PERSIMMON_RED } from 'constants/colors'
import { MAX_INPUT_LENGTH } from 'constants/index'

const UserInput = styled.div<{ textDisabled?: boolean }>`
  display: ${({ textDisabled }) => (textDisabled ? 'flex' : 'none')};
  height: 60px;
  background: $chat-window;
  z-index: 1;
  transition: 0.4s ease-in-out all;
  position: relative;
  margin-bottom: 4px;
`

const Input = styled.input`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #717477;
  background: ${WHITE};
  border: 1px solid #A0A2A5;
  border-radius: 8px;
  box-sizing: border-box;
  resize: none;
  margin-left: 16px;
  margin-bottom: 16px;
  opacity: ${props => (props.isHiddenTextInput ? 0 : 1)};
  transition: ${props =>
    (props.isHiddenTextInput
      ? 'opacity 300ms ease-out, visibility 1ms ease-out 300ms'
      : 'opacity 300ms ease-out 1ms, visibility 1ms ease-out')};
  &:focus {
    outline: 0;
    touch-action: none;
  }
`

const Button = styled.button`
  box-sizing: border-box;
  cursor: pointer;
  padding: 20px 31px 20px 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  opacity: ${props => (props.isHiddenTextInput ? 0 : 1)};
  visibility: ${props => (props.isHiddenTextInput ? 'hidden' : 'visible')};
  transition: ${props =>
    (props.isHiddenTextInput
      ? 'opacity 300ms ease-out, visibility 1ms ease-out 300ms'
      : 'opacity 300ms ease-out 1ms, visibility 1ms ease-out')};
`

const InputLengthIndicator = styled.div<{ length?: number }>`
  display: none;
  width: 24px;
  height: 24px;
  font-size: 10px;
  color: ${({ length }) => (length >= MAX_INPUT_LENGTH ? PERSIMMON_RED : SPUN_PEARL_GREY)};
`

export { UserInput, Input, Button, InputLengthIndicator }
