export const PERSIMMON_RED = '#ff624c'
export const COMET_BLUE = '#616581'
export const ATHENS_GREY = '#ebebef'
export const WHITE = '#fff'
export const RIBBON_BLUE = '#1658f3'
export const OXFORD_BLUE = '#354052'
export const MISCHKA_GRAY = '#D7D8DF'
export const FIORD_BLUE = '#3a3f62'
export const CORNFLOWER_BLUE = '#5a98f7'
export const MINE_SHAFT_BLACK = '#333333'
export const AZURE_BLUE = '#0084ff'
export const SPUN_PEARL_GREY = '#B0B2C0'
export const MISCHKA_GREY = '#D4D5DC'
export const HAWKES_BLUE = '#EEF5FE'
export const PALE_SKY = '#6D7686'
export const CADET_BLUE = '#acb0c0'
export const BACKGROUND_USER_MESSAGE = '#587377'