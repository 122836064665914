import React, { useContext, useEffect, useRef, useState } from 'react'

import { isElementTop } from 'actions/helpers'
import { getChatHistoryRequest } from 'api/websocketApi'
import MessageGroup from 'components/message/MessageGroup'
import { Typing } from 'components/message/Typing/Typing'
import { GPTMessage } from 'components/message/GPTMessage'
import { MESSAGE_SIZE, MessageType as MsgType, StreamActionType } from 'constants/index'
import { ScrollToButtonContext } from 'context/ScrollToButtonContext'
import { AGENT_JOINED, AGENT_LEFT, END_CONVERSATION, SESSION_EXPIRED } from 'constants/actionsType'
import { WebSocketsSharedContext } from 'integrations/WebSockets'
import { getMessagesFromHistory } from 'helper/getMessagesFromHistory'
import { usePrevious } from 'hooks/usePrevious'
import { MessageType } from 'models/MessageType'

import '../../message/Message.css'
import { messageContainerSize } from './config'
import CenteredLoader from '../../view/CenteredLoader'
import classes from './styles.module.scss'
import { useScrollOnOpenWidget } from './useScrollOnOpenWidget'
import { embeddedWidgetSizeSelector } from '../../../helper/embeddedWidgetSizeSelector'
import { BotPersonality } from 'models/SettingsType.d'

export const normalizeStreamingMessage = streamingMessage => {
  return {
    ...streamingMessage,
    type: MsgType.text,
    text: streamingMessage.stream.text,
  }
}

const initialChunk = { stream: { text: '' }, isFromBot: true }
export const aggregateChunksFromQueue = messagesQueue =>
  messagesQueue.reduce(
    (chunk1, chunk2) => ({
      ...chunk1,
      ...chunk2,
      stream: { text: chunk1.stream.text + (chunk2.stream.text || '') },
    }),
    initialChunk,
  )

const MessageContainer = props => {
  const {
    postMessage,
    showTyping,
    textDisabled,
    messages,
    isProcessingGPTMessage,
    setIsProcessingGPTMessage,
    GPTMessagesQueue,
    resetGPTMessagesQueue,
    updateMessages,
    isHiddenWidget,
    parentURL,
    device,
    settings,
    isFullScreenWidget,
    isMobile,
    getMoreChatHistory,
    firstUnreadMessageId,
    screenSize,
    isEmbedded,
    botPersonality
  } = props
  const [scrollPosition, setScrollPosition] = useState(0)
  const [messagesGroups, setMessagesGroups] = useState([])
  const [lastNonStatusOrSupportMessageGroupIdx, setLastNonStatusOrSupportMessageGroupIdx] = useState(-1)
  const { scrollBehavior, setScrollBehavior, scrollToBottom, messagesEnd } = useContext(ScrollToButtonContext)
  const { chatId, botId }: any = useContext(WebSocketsSharedContext)
  const [isClickedOnReaction, setIsClickedOnReaction] = useState(false)

  const scrollContainer = useRef(null)
  const firstUnreadMessageRef = useRef(null)
  const allowAutoScrollForGPTTyping = useRef<boolean>(true)
  const prevMessagesLength = usePrevious(messages?.length)
  const prevGroupLength = usePrevious(messagesGroups?.length)

  const statusMessages = [SESSION_EXPIRED, END_CONVERSATION, AGENT_LEFT, AGENT_JOINED]
  const showLoader = !showTyping && messages?.length <= 0
  const embeddedSize = embeddedWidgetSizeSelector()
  const containerSize = messageContainerSize(device, textDisabled, isFullScreenWidget, scrollBehavior,
    screenSize, isEmbedded, embeddedSize.width, embeddedSize.height)

  const [chunk, setChunk] = useState<MessageType | undefined>()

  const firstGPTQueueMessage = GPTMessagesQueue.at(0)
  const isFirstMessageStartAction =
    firstGPTQueueMessage && firstGPTQueueMessage.stream.action === StreamActionType.START

  useEffect(() => {
    const newMessage = aggregateChunksFromQueue(GPTMessagesQueue)
    const lastQueueMessage = GPTMessagesQueue.at(-1)

    setChunk(normalizeStreamingMessage(newMessage))

    if (lastQueueMessage && lastQueueMessage.stream.action === StreamActionType.STORED) {
      setIsProcessingGPTMessage(false)
      resetGPTMessagesQueue()

      if (isFirstMessageStartAction) {
        updateMessages([normalizeStreamingMessage(newMessage)])
      } else {
        handleGPTMessagesWithoutStartAction()
      }
      allowAutoScrollForGPTTyping.current = true
    }
  }, [GPTMessagesQueue.length])

  useEffect(() => {
    if (GPTMessagesQueue.length && allowAutoScrollForGPTTyping.current) {
      scrollToBottom('auto')
    }
  }, [GPTMessagesQueue.length, allowAutoScrollForGPTTyping.current])

  useScrollOnOpenWidget({
    isHiddenWidget,
    isProcessingGPTMessage,
    isGPTMessagesQueueNotEmpty: Boolean(GPTMessagesQueue.length),
    allowAutoScroll: allowAutoScrollForGPTTyping,
    scrollToBottom,
  })

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (firstUnreadMessageRef?.current) {
        firstUnreadMessageRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 200)

    return () => {
      clearTimeout(timerId)
    }
  }, [firstUnreadMessageRef?.current])

  useEffect(() => {
    if (prevMessagesLength !== messages?.length) {
      updateMessagesGroups()
    }

    if (isClickedOnReaction) {
      updateMessagesGroups()
      setIsClickedOnReaction(false)
    }
  }, [messages?.length, isClickedOnReaction])

  useEffect(() => {
    scrollToBottom()
  }, [showTyping, isFullScreenWidget])

  useEffect(() => {
    if (messagesGroups?.length - prevGroupLength === 1) {
      scrollToBottom()
    } else {
      updateScrollPosition()
    }
  }, [messagesGroups?.length])

  const updateScrollPosition = () => {
    scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight - scrollPosition
  }

  const handleScroll = ({ target }) => {
    const shouldAdjustScrollForGPTTyping =
      (isProcessingGPTMessage || GPTMessagesQueue.length) && scrollBehavior === 'auto' && isFirstMessageStartAction
    if (shouldAdjustScrollForGPTTyping) {
      adjustAutoScroll()
    }

    if (isElementTop(target) && target === scrollContainer.current) {
      setScrollBehavior('auto')
      setScrollPosition(scrollContainer.current.scrollHeight)
      getMoreChatHistory()
    }
  }

  const adjustAutoScroll = () => {
    const scrollDiff = scrollContainer.current.scrollHeight - scrollContainer.current.scrollTop
    const isScrolledToBottom = Math.abs(scrollDiff - scrollContainer.current.clientHeight) < 1

    if (!isScrolledToBottom) {
      allowAutoScrollForGPTTyping.current = false
    } else {
      allowAutoScrollForGPTTyping.current = true
    }
  }

  const handleGPTMessagesWithoutStartAction = () => {
    getChatHistoryRequest(chatId, botId, MESSAGE_SIZE).then(chatHistory => {
      const freshMessages = getMessagesFromHistory(chatHistory).filter(({ id }) => !messages.some(msg => msg.id === id))
      updateMessages(freshMessages)
    })
  }

  const shouldShowReply = (groupIdx, groups) => {
    const isLastMessage = groupIdx + 1 === groups.length
    const isLastMessageExcludingStatusMessages =
      lastNonStatusOrSupportMessageGroupIdx === -1 || lastNonStatusOrSupportMessageGroupIdx <= groupIdx
    return isLastMessage || isLastMessageExcludingStatusMessages
  }

  const updateMessagesGroups = () => {
    let messagesGroups = []
    let groupBot = []
    let groupUser = []

    messages.forEach((msg, index) => {
      if (msg?.isFromBot) {
        groupBot = [...groupBot, msg]
      } else if (!msg?.isFromBot) {
        groupUser = [...groupUser, msg]
      }

      if (msg?.isFromBot && (!messages[index + 1] || !messages[index + 1]?.isFromBot)) {
        messagesGroups = [...messagesGroups, groupBot]
        groupBot = []
      } else if (!msg?.isFromBot && (!messages[index + 1] || messages[index + 1]?.isFromBot)) {
        messagesGroups = [...messagesGroups, groupUser]
        groupUser = []
      }
    })
    setLastNonStatusOrSupportMessageGroupIdx(
      messagesGroups
        .map(group => group.some(msg => statusMessages.includes(msg?.action?.type) || msg?.customerSupportDetails))
        .lastIndexOf(false),
    )
    setMessagesGroups(messagesGroups)
  }

  const showGPTTyping = Boolean(GPTMessagesQueue.length) && !isFirstMessageStartAction
  const CenteredLoaderColor = botPersonality == BotPersonality.EXPERT ? '#355C7D' : '#00C19F'

  return (
    <div ref={scrollContainer} className={classes.msgWrapContainer} onScroll={handleScroll} style={containerSize}>
      {showLoader ? (
        <CenteredLoader position="relative" color={CenteredLoaderColor} size={40} />
      ) : (
        messagesGroups
          .slice()
          .map((group, groupIndex) => (
            <MessageGroup
              postMessage={msg => postMessage(msg)}
              key={groupIndex}
              group={group}
              device={device}
              parentURL={parentURL}
              settings={settings}
              isMobile={isMobile}
              isFullScreenWidget={isFullScreenWidget}
              showReply={shouldShowReply(groupIndex, messagesGroups)}
              firstUnreadMessageId={firstUnreadMessageId}
              firstUnreadMessageRef={firstUnreadMessageRef}
              isLastGroup={messagesGroups.length === groupIndex + 1}
              setIsClickedOnReaction={setIsClickedOnReaction}
            />
          ))
      )}

      {isProcessingGPTMessage && (
        <GPTMessage
          device={device}
          settings={settings}
          message={chunk || normalizeStreamingMessage(aggregateChunksFromQueue(GPTMessagesQueue))}
          showLogo
          isMobile={isMobile}
        />
      )}

      {showGPTTyping && <Typing />}

      {showTyping && <Typing />}

      <div className={classes.bottomScrollElem} ref={messagesEnd} />
    </div>
  )
}

export default MessageContainer
