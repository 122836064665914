import styled from 'styled-components'

const App = styled.div<{ isFullScreenWidget: boolean; isEmbedded: boolean }>`
  display: flex;
  position: ${({ isEmbedded }) => (!isEmbedded ? 'fixed' : 'absolute')};;
  bottom: ${({ isEmbedded }) => !isEmbedded && '0'};
  right: ${({ isEmbedded }) => !isEmbedded && '0'};
  top: ${({ isEmbedded }) => isEmbedded && '0'};
  left: ${({ isEmbedded }) => isEmbedded && '0'};
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ isEmbedded }) => !isEmbedded && 'flex-end'};
  overflow: hidden;
  z-index: 10010;
  width: ${({ isFullScreenWidget, isEmbedded }) => (isFullScreenWidget || isEmbedded) && '100%'};
  height: ${({ isFullScreenWidget, isEmbedded }) => (isFullScreenWidget || isEmbedded) && '100%'};
  padding: ${({ isFullScreenWidget }) => isFullScreenWidget && '0 25px'};
  box-sizing: ${({ isFullScreenWidget }) => isFullScreenWidget && 'border-box'};
`

const ButtonWrap = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  float: right;
`

export { App, ButtonWrap }
