import styled from 'styled-components'
import { OXFORD_BLUE } from 'constants/colors'

const Title = styled.p`
  margin: 0px 0px 12px 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: ${OXFORD_BLUE};
  text-align: center;
  letter-spacing: -0.02em;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 84px;
  box-sizing: border-box;
  resize: none;
  padding: 12px;
  border: 1px solid rgba(53, 64, 82, 0.1);
  border-radius: 10px;
  margin-bottom: 24px;
  outline: none;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`

export { Title, Textarea, ButtonsWrap }
