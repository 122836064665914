import styled from 'styled-components'
import { AZURE_BLUE, WHITE } from 'constants/colors'

const TemplateSlide = styled.div<{ screenWidth: number; enableReaction: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${WHITE};
  font-size: 16px;
  border-radius: 10px;
  font-weight: 500;
  margin: 0 12px 0 0;
  box-shadow: 0px 4px 10px rgba(19, 69, 186, 0.0655129);
  border: 1px solid rgba(19, 69, 186, 0.06);
  scroll-margin: 12px;
  position: relative;
  width: ${({ screenWidth }) => (screenWidth ? (screenWidth * 70) / 100 : 254)}px;
  & > a {
    color: ${AZURE_BLUE};
    font-weight: 500;
  }
`

const ButtonsWrap = styled.div<{ screenWidth: number }>`
  margin-top: auto;
  width: ${({ screenWidth }) => (screenWidth ? (screenWidth * 70) / 100 : 254)}px;
`

export { TemplateSlide, ButtonsWrap }
