import styled from 'styled-components'
import { WHITE, RIBBON_BLUE, MISCHKA_GREY, FIORD_BLUE } from 'constants/colors'

const QuickReplyMultiSelect = styled.button<{
  color: string
  isLarge: boolean
  isSelected: boolean
  isSubmitButton: boolean
  showSubmitButton: boolean
}>`
  animation: ${({ isAnimation }) => isAnimation && '0.5s messageAnimation'};
  margin: 2px 0px 3px 5px;
  display: ${({ isSubmitButton, showSubmitButton }) => (isSubmitButton && !showSubmitButton ? 'none' : 'flex')};
  justify-content: center;
  padding: 8px 16px;
  cursor: pointer;
  background: ${({ isSubmitButton, color }) => (isSubmitButton ? color || RIBBON_BLUE : WHITE)};
  border: 1px solid
    ${({ isSubmitButton, isSelected, color }) => (isSelected || isSubmitButton ? color || RIBBON_BLUE : MISCHKA_GREY)};
  color: ${({ isSelected, isSubmitButton, color }) => {
    if (isSelected) {
      return color || RIBBON_BLUE
    } else if (isSubmitButton) {
      return WHITE
    } else {
      return FIORD_BLUE
    }
  }};
  width: ${({ isLarge }) => isLarge && '-webkit-fill-available'};
  box-sizing: border-box;
  border-radius: 26px;
  line-break: loose;
  user-select: none;
  line-height: 1.2;
  outline: none;
  word-break: break-word;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  & > svg {
    display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
    min-width: 12px;
  }
`

export { QuickReplyMultiSelect }
