import styled from 'styled-components'
import { WHITE, COMET_BLUE } from 'constants/colors'

const MessagesPreview = styled.div<{ isPopupsNeeded: string }>`
  display: ${({ isPopupsNeeded }) => (isPopupsNeeded ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-end;
`

const Popup = styled.button<{ width: number }>`
  display: flex;
  padding: 16px;
  width: max-content;
  background: ${WHITE};
  box-shadow: ${({ width }) => (width ? '0px 0px 13px rgba(51, 51, 51, 0.1)' : '0px 0px 30px rgba(51, 51, 51, 0.1)')};
  border-radius: 10px;
  align-items: center;
  animation: toggleOpacity 0.7s;
  cursor: pointer;
  max-width: ${({ width }) => (width ? width - 120 : 380)}px;
  margin: ${({ width }) => (width ? '10px 0 10px 10px' : '8px 0 8px 8px')};
  border: none;
`

const Icon = styled.span`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-right: 16px;
  background-color: white;
  border: none;
  padding: 0;
`

const Text = styled.p`
  font-size: 16px;
  color: ${COMET_BLUE};
  margin: 0;
  text-overflow: ellipsis;
  overflow-x: hidden;
`

export { MessagesPreview, Popup, Icon, Text }
