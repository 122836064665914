export const checkShowLogo = (messages, index) => {
  return (
    messages[index].isFromBot &&
    (messages[index + 1]
      ? messages[index].isFromBot && !messages[index + 1].isFromBot
      : messages.length === index + 1 && messages[index].isFromBot)
  )
}

export const defineMessageBorder = (messages, index) => {//NOSONAR
  if (messages[index].isFromBot) {
    const hasPrevReceived = messages[index - 1] && messages[index - 1].isFromBot && !messages[index - 1].action
    const hasNextReceived = messages[index + 1] && messages[index + 1].isFromBot && !messages[index + 1].action

    if (
      hasNextReceived &&
      (!messages[index - 1] || !messages[index - 1].isFromBot || messages[index - 1].action)
    )
      return '17px 17px 17px 2px'
    if (hasPrevReceived && hasNextReceived) return '2px 17px 17px 2px'
    if (
      hasPrevReceived &&
      (!messages[index + 1] || !messages[index + 1].isFromBot || messages[index + 1].action)
    )
      return '2px 17px 17px 17px'
  }

  if (!messages[index].isFromBot) {
    // eslint-disable-next-line no-redeclare
    const hasPrevReceived = messages[index - 1] && (!messages[index - 1].isFromBot && !messages[index - 1].action)
    // eslint-disable-next-line no-redeclare
    const hasNextReceived = messages[index + 1] && (!messages[index + 1].isFromBot && !messages[index + 1].action)

    if (
      hasNextReceived &&
      (!messages[index - 1] || messages[index - 1].isFromBot || messages[index - 1].action)
    )
      return '17px 17px 2px 17px'
    if (hasPrevReceived && hasNextReceived) return '17px 2px 2px 17px'
    if (
      hasPrevReceived &&
      (!messages[index + 1] || messages[index + 1].isFromBot || messages[index + 1].action)
    )
      return '17px 2px 17px 17px'
  }
}

export const messageContainerSize = (
  device,
  textEnable,
  isFullScreenWidget,
  scrollBehavior,
  screenSize,
  isEmbedded,
  embeddedWidth,
  embeddedHeight
) => {
  let customHeight, customWidth
  if (isEmbedded) {
    customHeight = parseInt(embeddedHeight.replace('px', ''))
    customWidth = parseInt(embeddedWidth.replace('px', ''))
    customWidth = '100%'
  }
  let needFullScreen = false
  if (embeddedHeight === '100%' && embeddedWidth === '100%' && isEmbedded) {
    needFullScreen = true
  }

  const marginBottom = 65
  const horizontalMargin = 0
  const chatInputHeightAndPadding = (device?.height > 0 || customHeight > 100) ? 126 : 0
  const needCustomSize = customHeight > 100 || customWidth > 100 || device?.height > 0 || device?.width > 0
  const heightWithoutInput = needCustomSize ?
    ((`${(customHeight - marginBottom) || (device.height - marginBottom)}px`)) : (needFullScreen ? '100%' : '413px')
  const heightWithInput = needCustomSize ?
    ((`${(customHeight - chatInputHeightAndPadding) || (device.height - chatInputHeightAndPadding)}px`))
    : (needFullScreen ? '100%' : '413px')
  const maxHeightWithoutInput = needCustomSize ?
    ((`${(customHeight - marginBottom) || (device.height - marginBottom)}px`))
    : (needFullScreen ? '100%' : `${screenSize.height - 186}px`)
  const maxHeightWithInput = needCustomSize ?
    ((`${(customHeight - chatInputHeightAndPadding) || (device.height - chatInputHeightAndPadding)}px`))
    : (needFullScreen ? '100%' : `${screenSize.height - 246}px`)
  const widgetFullScreenHeight = textEnable ? `calc(100% - ${chatInputHeightAndPadding}px)` : '100%'

  if (isFullScreenWidget) {
    return {
      height: widgetFullScreenHeight,
      width: customWidth || (needCustomSize ? `${device.width - horizontalMargin}px` : '100%'),
      scrollBehavior,
    }
  }

  return {
    height: textEnable ? heightWithInput : heightWithoutInput,
    width: customWidth || (needCustomSize ? `${device.width - horizontalMargin}px` : '100%'),
    maxHeight: textEnable ? maxHeightWithInput : maxHeightWithoutInput,
    scrollBehavior,
  }
}

export const chatSize = (height, width, isFullScreenWidget, isEmbedded, embeddedWidth, embeddedHeight) => {
  const headerHeight = 65
  let needFullScreen = false
  if (embeddedHeight === '100%' && embeddedWidth === '100%' && isEmbedded) {
    needFullScreen = true
  }

  if (isFullScreenWidget) {
    return {
      height: `calc(100% - ${headerHeight}px)`,
      width: '100%',
    }
  }

  return {
    height: height > 0 ? `${height - headerHeight}px` : needFullScreen ? '100%' : 'auto',
    width: width > 0 ? `${width}px` : '100%',
    overflow: (isEmbedded && embeddedHeight === '100%') || needFullScreen ? 'hidden' : 'auto',
  }
}
