import styled from 'styled-components'
import { RIBBON_BLUE, WHITE, COMET_BLUE, PERSIMMON_RED, MISCHKA_GRAY, FIORD_BLUE } from 'constants/colors'

const StartScreen = styled.div<{ width: number }>`
  position: relative;
  background: ${WHITE};
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  border-radius: 10px;
  height: auto;
  display: flex;
  flex-direction: column;
  bottom: 20px;
  right: 20px;
  user-select: none;
  width: ${({ width }) => (width > 0 ? width - 10 : 360)}px;
  margin: ${({ width }) => (width > 0 ? '5px 5px 20px' : '15px 10px 20px 10px')};
  @media screen and (max-width: 450px) {
    bottom: 0;
    right: 0;
  }
`

const Form = styled.form`
  padding: 32px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const Description = styled.p`
  font-size: 16px;
  color: ${COMET_BLUE};
  margin: 0;
`

const Error = styled.p`
  position: absolute;
  color: ${PERSIMMON_RED};
  font-size: 11px;
  width: 296px;
  margin-top: 0px;
`

const Input = styled.input<{ error: boolean; withButton: boolean }>`
  background: ${WHITE};
  border: 1px solid ${({ error }) => (error ? PERSIMMON_RED : MISCHKA_GRAY)};
  border-radius: ${({ withButton }) => (withButton ? '10px 0 0 10px' : '10px')};
  width: calc(100% - ${({ withButton }) => (withButton ? 78 : 0)}px);
  height: 45px;
  margin-top: 18px;
  font-size: 16px;
  color: ${({ error }) => (error ? PERSIMMON_RED : FIORD_BLUE)};
  padding: 0 25px;
  box-sizing: border-box;
  &::placeholder {
    font-size: 16px;
    color: ${MISCHKA_GRAY};
    margin-left: 25px;
  }
`

const Button = styled.button<{ withInput: boolean; color: string }>`
  position: ${({ withInput }) => withInput && 'absolute'};
  right: ${({ withInput }) => withInput && '32px'};
  float: right;
  height: 45px;
  width: 80px;
  background: ${({ color }) => color || RIBBON_BLUE};
  border: 1px solid ${({ color }) => color || RIBBON_BLUE};
  color: ${WHITE};
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-top: 18px;
  border-radius: ${({ withInput }) => (withInput ? '0 10px 10px 0' : '10px')};
`

export { StartScreen, Form, Description, Error, Input, Button }
