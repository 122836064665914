import styled from 'styled-components'
import { ATHENS_GREY, RIBBON_BLUE, WHITE } from 'constants/colors'

const StartScreenHeader = styled.div<{ color: number }>`
  background: ${({ color }) => color || RIBBON_BLUE};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 32px 24px;
  height: 125px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
`

const Logo = styled.img`
  height: 60px;
  width: 60px;
  cursor: pointer;
  animation-duration: 0.3s;
  animation-name: appear;
  border-radius: 50%;
`

const TextWrap = styled.div`
  margin-left: 24px;
  width: 228px;
`

const Title = styled.p`
  font-size: 20px;
  color: ${WHITE};
  margin: 0;
`

const SubTitle = styled.p`
  font-size: 16px;
  color: ${ATHENS_GREY};
  margin: 0;
  max-width: 228px;
`

export { StartScreenHeader, Logo, TextWrap, Title, SubTitle }
