import { embeddedWidgetSizeSelector } from '../helper/embeddedWidgetSizeSelector'

const embeddedSize = embeddedWidgetSizeSelector()

export const WIDGET_HEIGHT = '648px'
export const WIDGET_WIDTH = '384px'
export const BUBBLE_WIDTH = '80px'
export const BUBBLE_HEIGHT = '80px'
export const EMBEDDED_WIDGET_HEIGHT = embeddedSize.height
export const EMBEDDED_WIDGET_WIDTH = embeddedSize.width
